import React, { FC } from 'react';
import { Container, Icon, Text } from '@legalshield/adonis-ux-framework';

import './CardLink.scss';
import { Link, LinkProps } from 'react-router-dom';

interface CardLinkProps extends LinkProps {
  ids?: boolean;
  to: string;
  text?: string;
  iconColor?: string;
}

const CardLink: FC<CardLinkProps> = ({ ids, to, text = '', iconColor, ...linkProps }) => (
  <Link className="card-link" to={to} style={ids ? { color: '#3050F0' } : { color: '#8231d4' }} {...linkProps}>
    <Container alignItems="center" flexbox flexDirection="row" classNames={['pt-4']}>
      <Text text={text} textSize="medium" textWeight="semibold" />
      <Icon name="arrow_right_lg" color={iconColor} classNames={['ml-2']} />
    </Container>
  </Link>
);

export default CardLink;
