import React, { FC, useEffect } from 'react';

import { ButtonBar } from '../../Controls/ButtonBar';
import { NamePresenter } from '../Presenters';
import { useForm } from '@legalshield/adonis-ux-framework';
import { useUpdateName } from '../../../hooks/useProfile';

export interface NameProps {
  data: NamePresenter;
  onClose: (failure?: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loader: any;
}

export const NameField: FC<NameProps> = ({ data, onClose, loader }: NameProps) => {
  const firstNameProp = data?.firstName;
  const lastNameProp = data?.lastName;
  const middleNameProp = data?.middleName;
  const version = data?.version;
  const [formValues, formObject] = useForm(
    {
      firstName: {
        errorMessage: string_table.PROFILE_FIELD_REQUIRED,
        label: `${string_table.PROFILE_FIRST_NAME}`,
        placeHolder: firstNameProp ?? '',
        required: true,
        type: 'text',
        value: firstNameProp ?? '',
      },
      middleName: {
        label: `${string_table.PROFILE_MIDDLE_NAME}`,
        placeHolder: middleNameProp ?? '',
        type: 'text',
        value: middleNameProp ?? '',
      },
      // eslint-disable-next-line sort-keys
      lastName: {
        errorMessage: string_table.PROFILE_FIELD_REQUIRED,
        label: `${string_table.PROFILE_LAST_NAME}`,
        placeHolder: lastNameProp ?? '',
        required: true,
        type: 'text',
        value: lastNameProp ?? '',
      },
    },
    null,
  );

  useEffect(() => {
    const newValues = {
      ...formValues,
      firstName: { ...formValues['firstName'], hasChanged: true, value: firstNameProp },
      lastName: { ...formValues['lastName'], hasChanged: true, value: lastNameProp },
      middleName: { ...formValues['middleName'], hasChanged: true, value: middleNameProp },
    };
    formObject.setValues(newValues);
  }, [firstNameProp, lastNameProp, middleNameProp]);

  const { firstName, middleName, lastName } = formValues;
  const { mutate, isLoading, isSuccess, isError } = useUpdateName(version);
  const updateName = async () => {
    mutate({
      firstName: firstName.value,
      lastName: lastName.value,
      middleName: middleName.value,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      loader.Success(string_table.ALERT_SUCCESS);
      onClose();
    } else if (isError) {
      loader.Error(string_table.ALERT_ERROR);
      onClose();
    } else if (isLoading) {
      loader.Loading();
    }
  }, [isSuccess, isError, isLoading]);

  return (
    <>
      <div id="nameForm" data-testid="nameForm">
        {formObject.renderForm()}
      </div>
      <ButtonBar
        alignLeft={true}
        id={'nameBtnBar'}
        data-testid={'nameBtnBar'}
        disableSave={!formObject.isValid || !formObject.hasChanged}
        onSave={() => updateName()}
      />
    </>
  );
};
