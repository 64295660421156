import React, { FC } from 'react';
import { Button, Container, Heading, Icon, Text, buildURL } from '@legalshield/adonis-ux-framework';
import { Link } from 'react-router-dom';

import Config from '../../../../config';
import URLs from '../../../../utils/url';
import { EntitlementPresenter } from '../../../../presenters';
import { PlanControl } from '../../../Controls/PlanControl';
import GtmService from '../../../../services/gtm.service';

export interface PlanFamilies {
  [key: string]: EntitlementPresenter[];
}

interface PlanFamilyProps {
  title: string;
  icon: string;
}

interface PlanFamilyTitles {
  [key: string]: PlanFamilyProps;
}

interface EntitlementProps {
  index: number | string;
  presenter: EntitlementPresenter;
  orderedPlanFamilies: PlanFamilies;
  family: string;
}

export const Entitlement: FC<EntitlementProps> = ({ presenter, index, family, orderedPlanFamilies }) => {
  const presenters = orderedPlanFamilies[family];
  const url = presenters.length > 0 ? getAppUrl(presenters[index]) : '/';

  const Launch = 'Launch';

  const productFamilyTitles: PlanFamilyTitles = {
    identity: { icon: 'id', title: 'IDShield' },
    legal: { icon: 'legal', title: 'LegalShield' },
  };

  function getAppUrl(presenter: EntitlementPresenter): string {
    switch (presenter.entitlement.product.productFamily) {
      case 'Identity':
        if (window.location.href.includes(':3401')) {
          return buildURL(URLs.getLSAppUrl(Config.currentEnv.apps.ids + '.' + Config.currentEnv.domain), {
            e: presenter.entitlement.id,
          });
        } else return buildURL(URLs.getLSAppUrl(Config.currentEnv.apps.ids), { e: presenter.entitlement.id });
      case 'Legal':
        if (window.location.href.includes(':3401')) {
          return buildURL(URLs.getLSAppUrl(Config.currentEnv.apps.legal + '.' + Config.currentEnv.domain), {
            e: presenter.entitlement.id,
          });
        } else return buildURL(URLs.getLSAppUrl(Config.currentEnv.apps.legal), { e: presenter.entitlement.id });
      default:
        return buildURL(URLs.getLSAppUrl(Config.currentEnv.apps.legal), { e: presenter.entitlement.id });
    }
  }

  function goToLaunch(presenter: EntitlementPresenter) {
    window.location.href = buildURL(URLs.getLSAppUrl(Config.currentEnv.apps.launch), {
      e: presenter.entitlement.id,
    });
  }

  const captureEntitlementClick = (title: string, action_type: string) => {
    // trigger GA4 custom event for view entitlement
    GtmService.viewEntitlementGtmEvent(title, action_type);
  };

  return (
    <Link
      key={index}
      to={url}
      onClick={() =>
        captureEntitlementClick(presenter.entitlement.product.productFamily, presenter.entitlement.product.productType)
      }
      className="lsux-card accounts-card accounts-card--clickable p-5"
    >
      <Container flexbox flexDirection="column" background="white">
        <Icon name={`apps_${productFamilyTitles[family].icon}_shield`} size="xlarge" />
        <Heading as="T20" text={productFamilyTitles[family].title} classNames={['mt-4']} />
        <PlanControl
          key={index}
          planTitle={presenter.productFriendlyName()}
          classNames={['p-4']}
          showExternalLink={false}
        >
          <Text classNames={['pt-3', 'accounts-card__text']}>
            {`${string_table.PRODUCTS_MEMBERNUMBER}: ${presenter.entitlementFriendlyId()}`}
          </Text>
          <Text textSize="small" classNames={['pt-3', 'accounts-card__text']}>
            {`${presenter.entitlement.authorizedUsers?.length || '0'} ${string_table.PRODUCTS_MEMBERS_ADDITIONAL}`}
          </Text>
          {presenter.hasLaunch() && (
            <Button
              classNames={['mt-3', 'product-launch-button']}
              label={presenter.getSupplementFriendlyName(Launch)}
              onClick={(e) => {
                e.stopPropagation();
                goToLaunch(presenter);
              }}
              textSize="small"
              variant="tertiary"
              style={{ border: 0, paddingLeft: 0 }}
            />
          )}
        </PlanControl>
      </Container>
    </Link>
  );
};
