// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-dropdown-item.active {
  background-color: var(--brand-color-prim50);
  color: var(--brand-color-prim300);
}`, "",{"version":3,"sources":["webpack://./src/components/Payments/PaymentDropdown.scss"],"names":[],"mappings":"AAAA;EACE,2CAAA;EACA,iCAAA;AACF","sourcesContent":[".payment-dropdown-item.active {\n  background-color: var(--brand-color-prim50);\n  color: var(--brand-color-prim300);\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
