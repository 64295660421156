import { useQuery } from '@tanstack/react-query';
import classiciSeriesService from '../../../services/classiciSeriesService';


const getPrecancelStatuses = async (membershipId: string) => {
  const statuses = await classiciSeriesService.getPrecancelStatuses(membershipId);

  return statuses;
};


export const useGetPrecancelStatuses = (membershipId: string) => {
  return useQuery({
    queryFn: () => getPrecancelStatuses(membershipId),
    queryKey: ['precancelStatus', membershipId],
    enabled: !!membershipId,
  });
};

