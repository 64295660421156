import { Button, ListItem } from '@legalshield/adonis-ux-framework';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { EmailEditor } from '../EmailEditor/EmailEditor';
import { EmailResource } from '@legalshield/frontend-commons/dist/sdk/profiles';
import { sortByPropAndPreferred } from '../../../utils/utils';
import { EmailPresenter } from '../Presenters';

export interface EmailFieldProps {
  data: EmailPresenter;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loader: any;
}

export interface IEmailPresenter extends EmailResource {
  isNew: boolean;
}

export const EmailField: FC<EmailFieldProps> = ({ data, loader }: EmailFieldProps) => {
  const [indexBeingEdited, setIndexBeingEdited] = useState<number | null>(null);
  const [sortedEmailAddresses, setSortedEmailAddresses] = useState<IEmailPresenter[]>([]);
  const emails = data.emails;
  const version = data.version;

  useEffect(() => {
    setSortedEmailAddresses(sortByPropAndPreferred(emails, 'address', true));
  }, [emails]);

  const emptyEmailAddressObject = useMemo(() => {
    return {
      address: '',
      isNew: true,
      preferred: false,
      verified: false,
    };
  }, []);

  const addNewEmailRow = () => {
    loader.Blank();
    const newArray = sortedEmailAddresses.concat(emptyEmailAddressObject);
    setSortedEmailAddresses(newArray);
    setIndexBeingEdited(newArray.length - 1);
  };

  const removeNewEmailRow = () => {
    setIndexBeingEdited(null);
    const newArray = sortedEmailAddresses.slice(0, sortedEmailAddresses.length - 1);
    setSortedEmailAddresses(newArray);
  };

  return (
    <>
      <div className={'emc-list-items'}>
        {sortedEmailAddresses?.map((email, index) => {
          return (
            <ListItem key={index} classNames={['pb-4', 'pt-4']}>
              <div
                id={`EM${index}`}
                data-testid={`EM${index}`}
                style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
              >
                <EmailEditor
                  index={index}
                  indexBeingEdited={indexBeingEdited}
                  address={email.address}
                  preferred={email.preferred}
                  version={version}
                  onRemoveNewEmail={removeNewEmailRow}
                  onEditEmail={setIndexBeingEdited}
                  loader={loader}
                />
              </div>
            </ListItem>
          );
        })}
      </div>
      <Button
        id="addEmailBtn"
        data-testid="addEmailBtn"
        classNames={['mt-3']}
        disabled={indexBeingEdited !== null}
        iconLeft={'edit_add_plus_circle_filled'}
        iconSize="small"
        iconColor="BRAND200"
        label={string_table.ADD_EMAIL}
        onClick={() => addNewEmailRow()}
        variant="tertiary"
      />
    </>
  );
};
