import { useThemisAnalytics } from '../hooks/useThemisAnalytics';

const viewBeginUpgradeGtmEvent = () => {
  // trigger GA4 custom event for view begin upgrade
  useThemisAnalytics().triggerEvent('view_begin_upgrade');
};

const beginUpgradeGtmEvent = () => {
  // trigger GA4 custom begin upgrade event
  useThemisAnalytics().triggerEvent('begin_upgrade');
};

const viewUpgradeConfirmationGtmEvent = () => {
  // trigger GA4 custom view upgrade confirmation event
  useThemisAnalytics().triggerEvent('view_upgrade_confirmation');
};

const upgradeThumbsUpGtmEvent = () => {
  // trigger GA4 custom thumbs up event
  useThemisAnalytics().triggerEvent('upgrade_thumbs_up');
}

const upgradeThumbsDownGtmEvent = () => {
  // trigger GA4 custom thumbs up event
  useThemisAnalytics().triggerEvent('upgrade_thumbs_down');
}

const viewEntitlementGtmEvent = (title: string) => {
  // trigger GA4 custom event for view entitlement
  useThemisAnalytics().triggerCustomEvent('click_button', {title: title, action_type: 'view_entitlement'});
}

const linkMembershipFillInputGtmEvent = (form_id: string, user_id: string) => {
  // trigger GA4 custom event for tracking filled in but not submitted input in the link membership modal
  useThemisAnalytics().triggerCustomEvent('form_start', {form_id: form_id, user_id: user_id});
}

const linkMembershipSubmitInputGtmEvent = (title: string, user_id: string)  => {
  // trigger GA4 custom event for tracking submitted code number input in the link membership modal
  useThemisAnalytics().triggerCustomEvent('click_button', {title: title, user_id: user_id});
}

const linkMembershipOutcomeGtmEvent = (title: string, action_type: number, user_id: string) => {
  // trigger GA4 custom event for tracking successful or unsucessful completion of the link membership flow
  useThemisAnalytics().triggerCustomEvent('click_button', {title: title, action_type: action_type, user_id: user_id});

}

export default {
  beginUpgradeGtmEvent,
  linkMembershipFillInputGtmEvent,
  linkMembershipOutcomeGtmEvent,
  linkMembershipSubmitInputGtmEvent,
  upgradeThumbsUpGtmEvent,
  upgradeThumbsDownGtmEvent,
  viewBeginUpgradeGtmEvent,
  viewEntitlementGtmEvent,
  viewUpgradeConfirmationGtmEvent,
};
