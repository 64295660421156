import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Container, Icon, Text, Title } from '@legalshield/adonis-ux-framework';

import './CardButton.scss';

interface CardButtonProps {
  to?: string;
  iconName?: string;
  imageSrc?: string;
  heading: string;
  text?: string;
}

const CardButton: FC<CardButtonProps> = ({ iconName = '', heading, text, to, imageSrc }) => (
  <Link to={to} className="lsux-card accounts-card accounts-card--clickable">
    <Container flexbox flexDirection="column" classNames={['p-5']}>
      {imageSrc ? (
        <img src={imageSrc} alt={heading} className="account-card--img" />
      ) : (
        <Icon name={iconName} size="xlarge" color="N800" />
      )}
      <Title textSize={'large'} text={heading} classNames={'pt-4'} />
      <Text textSize="large" text={text} classNames={['pt-3', 'card-button__text']} />
    </Container>
  </Link>
);

export default CardButton;
