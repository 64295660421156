// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tippy-box[data-placement^=top] > .tippy-arrow {
  bottom: 0;
  left: -110px !important;
}

.tippy-box {
  background-color: #000000 !important;
  width: 297px;
}

.tippy-box[data-theme~=material][data-placement^=top] > .tippy-arrow:before {
  border-top-color: #000000 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Payments/Subscriptions/LinkMembershipModal/LinkMembershipModal.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,uBAAA;AACF;;AAEA;EACE,oCAAA;EACA,YAAA;AACF;;AAEA;EACE,oCAAA;AACF","sourcesContent":[".tippy-box[data-placement^=top]>.tippy-arrow {\n  bottom: 0;\n  left: -110px !important;\n}\n\n.tippy-box {\n  background-color: #000000 !important;\n  width: 297px;\n}\n\n.tippy-box[data-theme~=material][data-placement^=top]>.tippy-arrow:before {\n  border-top-color: #000000 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
