export const isStorybook = (): boolean => {
    try {
      return typeof window !== 'undefined' && (
        // Check multiple possible Storybook indicators
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).__STORYBOOK_CLIENT_API__ !== undefined ||
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).__STORYBOOK_PREVIEW__ !== undefined ||
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).__STORYBOOK_STORY_STORE__ !== undefined ||
        document.getElementById('storybook-root') !== null ||
        document.getElementById('root')?.getAttribute('data-storybook') !== null
      );
    } catch {
      return false;
    }
};