import React, { FC, useState } from 'react';
import { Accordion, Button, Container, Text } from '@legalshield/adonis-ux-framework';

import LinkMembershipModal from './LinkMembershipModal/LinkMembershipModal';
import { ISubscriptionResource } from '../hooks/utils/subscription-utils';
import { Subscription } from './Subscription/Subscription';

import './Subscriptions.scss';

interface SubscriptionsProps {
  subscriptions: ISubscriptionResource[];
  isLoading: boolean;
}

const Subscriptions: FC<SubscriptionsProps> = ({ subscriptions = [], isLoading }) => {
  const [showActivateModal, setShowActivateModal] = useState(false);

  return (
    <Container classNames={['accounts-subscriptions']} flexbox flexDirection="column">
      <Accordion.Root type="multiple" defaultValue={[subscriptions?.map[0]?.id ?? '']}>
        {isLoading ? (
          <>
            <Subscription isLoading={true} />
            <Subscription isLoading={true} />
          </>
        ) : subscriptions.length ? (
          subscriptions.map((sub) => <Subscription key={sub?.id} subscription={sub} />)
        ) : (
          <>
            <Text text="No subscriptions found" classNames={['my-4']} />
            <Button
              label={string_table.LINK_MEMBERSHIP_SUBMIT_BUTTON_TEXT}
              onClick={() => setShowActivateModal(true)}
              classNames={['mt-5']}
            />
            {showActivateModal && (
              <LinkMembershipModal
                onOpenChange={setShowActivateModal}
                closeButton
                closeFunction={() => setShowActivateModal(false)}
              />
            )}
          </>
        )}
      </Accordion.Root>
    </Container>
  );
};

export default Subscriptions;
