import React, { FC, useEffect } from 'react';
import { Card, Divider, Loader, useLoader } from '@legalshield/adonis-ux-framework';
import { AddressResource, EmailResource } from '@legalshield/frontend-commons/dist/sdk/profiles';

import { Layout } from '../Layout/Layout';
import { EditContainer } from './EditContainer/EditContainer';
import {
  AddressPresenter,
  DateOfBirthPresenter,
  NamePresenter,
  EmailPresenter,
  PhoneNumberPresenter,
  PreferredNamePresenter,
} from './Presenters';
import RealmUtility from '../../utils/realm';
import { formatDate2Digit } from '../../helpers/stringFormatter';
import { getPreferred, sortByPropAndPreferred } from '../../utils/utils';
import { useGetProfile } from '../../hooks/useProfile';

const Profile: FC = () => {
  const { data: profileData, isLoading, isSuccess } = useGetProfile();
  const loader = useLoader();

  useEffect(() => {
    if (isLoading) {
      loader.Loading();
    } else if (isSuccess) {
      loader.Blank();
    }
  }, [isLoading, isSuccess]);

  const nameData: NamePresenter | null =
    isSuccess && profileData
      ? new NamePresenter(profileData.firstName, profileData.middleName, profileData.lastName, profileData.version)
      : null;
  const preferredNameData: PreferredNamePresenter | null =
    isSuccess && profileData ? new PreferredNamePresenter(profileData.preferredName, profileData.version) : null;
  const dateOfBirthData: DateOfBirthPresenter | null =
    isSuccess && profileData
      ? new DateOfBirthPresenter(
          profileData.dateOfBirth,
          formatDate2Digit(profileData.dateOfBirth || ''),
          profileData.version,
        )
      : null;
  const sortedEmailData: EmailResource[] =
    isSuccess && profileData ? sortByPropAndPreferred(profileData.emails, 'address', true) : [];
  const emailData: EmailPresenter | null =
    isSuccess && profileData ? new EmailPresenter(sortedEmailData, profileData.version) : null;
  const sortedPhoneNumberData =
    isSuccess && profileData ? sortByPropAndPreferred(profileData.phoneNumbers, 'number', true) : [];
  const phoneNumberData: PhoneNumberPresenter | null =
    isSuccess && profileData ? new PhoneNumberPresenter(sortedPhoneNumberData, profileData.version) : null;
  const preferredAddress: AddressResource | null =
    isSuccess && profileData ? getPreferred(profileData.addresses) : null;
  const fullAddressString = preferredAddress
    ? `${preferredAddress.address1}, ${preferredAddress.address2 || ''} ${preferredAddress.locality}, ` +
      `${preferredAddress.administrativeArea} ${preferredAddress.postalCode} ${preferredAddress.country}`
    : '';
  const addressData: AddressPresenter | null =
    isSuccess && profileData ? new AddressPresenter(preferredAddress, fullAddressString, profileData.version) : null;

  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: string_table.PROFILE_TITLE, link: '/overview/profile' },
  ];

  return (
    <Layout crumbs={crumbs} title={string_table.NEW_PROFILE_TITLE} subtitle={string_table.NEW_PROFILE_DESCRIPTION}>
      {isLoading ? (
        <Loader loaderState={loader.loaderState} />
      ) : (
        <Card>
          <Card.Content>
            <div className="px-4 py-1">
              <EditContainer data-testid="mainNameContainer" fieldName={'name'} data={nameData} />
              <Divider />
              <EditContainer
                data-testid="mainPreferredNameContainer"
                fieldName={'preferredName'}
                data={preferredNameData}
              />
              <Divider />
              {!RealmUtility.isGroupAdmin() && (
                <>
                  <EditContainer
                    data-testid="mainDateOfBirthContainer"
                    fieldName={'dateOfBirth'}
                    data={dateOfBirthData}
                  />
                  <Divider />
                </>
              )}
              <EditContainer data-testid="mainEmailContainer" fieldName={'email'} data={emailData} />
              <Divider />
              <EditContainer data-testid="mainPhoneNumberContainer" fieldName={'phoneNumber'} data={phoneNumberData} />
              <Divider />
              {!RealmUtility.isGroupAdmin() && (
                <EditContainer data-testid="mainAddressContainer" fieldName={'address'} data={addressData} />
              )}
            </div>
          </Card.Content>
        </Card>
      )}
    </Layout>
  );
};

export default Profile;
