import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Container, Image, Text } from '@legalshield/adonis-ux-framework';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { ISubscriptionResource } from '../../hooks/utils/subscription-utils';
import { SubscriptionDetails } from './SubscriptionDetails';
import { formatExactDate, isAssociate, isLegal, isPostalCodeCanadian } from '../../utils/utils';
import { getFriendlyProductNameFromSubscription } from '../../../../utils/getFriendlyName';
import { isUpgradeSuccessful, useUpgradeableSubscriptions } from '../../utils/getUpgradeableSubscriptions';
import { useLaunchDarkly } from '../../../../hooks/useLaunchDarkly';
import { getBillingFrequency } from '../../../../components/Payments/utils/getBillingFrequency';

import './Subscription.scss';

interface SubscriptionProps {
  subscription?: ISubscriptionResource;
  isLoading?: boolean;
}

interface SubscriptionPreviewProps {
  logo: string;
  price: string;
  productName: string;
  realmDomain: string;
  subtitle?: string;
  showUpgradeButton: boolean;
  onUpgradeClick?: () => void;
}

const SubscriptionPreview: FC<SubscriptionPreviewProps> = ({
  logo,
  price,
  productName,
  realmDomain,
  subtitle,
  showUpgradeButton,
  onUpgradeClick,
}) => {
  const { upgradeFlow } = useLaunchDarkly();

  return (
    <div className="accounts-subscription-preview">
      <Container flexbox flexDirection="row">
        <Container>
          <Image
            id="product-logo"
            classNames={['mr-4']}
            height={32}
            width={32}
            src={'https://design.api.' + realmDomain + '/assets/logos/' + logo + '-square-logo.svg'}
            alt={string_table.PRODUCT_LOGO_ALT}
          />
        </Container>
        <Container flexbox flexDirection="column" alignItems="flex-start">
          <Text textSize="extra-large" id="subscription-name" text={productName} />
          <Container flexbox flexDirection="row" alignItems="center" classNames={['pt-2']}>
            <Text textSize="large" classNames={['accounts-subscription-item__header-price', 'pr-2']}>
              {price}
            </Text>
            {subtitle && (
              <Text textSize="medium" classNames={['accounts-subscription-item__header-bill-date']}>
                {subtitle}
              </Text>
            )}
          </Container>
        </Container>
      </Container>
      {upgradeFlow && showUpgradeButton && (
        <Container flexbox flexDirection="row" justifyContent="flex-end" alignItems="flex-end">
          <Button label={string_table.UPGRADE_CTA_TEXT} onClick={onUpgradeClick} />
        </Container>
      )}
    </div>
  );
};

const SubscriptionPreviewSkeleton: FC = () => (
  <>
    <Container>
      <Skeleton width={32} height={32} className="mr-4" />
    </Container>
    <Container flexbox flexDirection="column" alignItems="flex-start">
      <Skeleton count={1} height={28} width={260} />
      <Container flexbox flexDirection="row" alignItems="center" classNames={['pt-2']}>
        <Skeleton count={1} height={22} width={160} className="accounts-subscription-item__header-price mr-2" />
        <Skeleton count={1} height={18} width={160} className="accounts-subscription-item__header-bill-date" />
      </Container>
    </Container>
  </>
);

export const Subscription: FC<SubscriptionProps> = ({ subscription, isLoading }) => {
  const paidToDate = formatExactDate(subscription?.paidToDate ?? subscription?.nextBillOn);

  const isLegalPlan = isLegal(subscription?.productBrochure?.productFamily);
  const isAssociatePlan = isAssociate(subscription?.productBrochure?.productFamily);
  const logo: string = isLegalPlan ? 'legalshield' : isAssociatePlan ? 'pplsi' : 'idshield';
  const isSubscriptionCanadian = isPostalCodeCanadian(subscription?.postalCode);
  const billingPeriod = getBillingFrequency(subscription?.billingFrequency?.period);
  const billingFrequency = isSubscriptionCanadian
    ? `${string_table.PRODUCT_PER} ${billingPeriod} + ${string_table.PRODUCT_TAX}`
    : `${string_table.PRODUCT_PER} ${billingPeriod}`;
  const price = `$${subscription?.price?.toFixed(2)} ${billingFrequency}`;
  const isSubscriptionCancelled = subscription?.status === 'CANCELLED';

  const { data: upgradesData } = useUpgradeableSubscriptions();

  // Determine if the subscription is upgradable
  const upgrade = upgradesData?.find((sub) => sub.subscription.id === subscription.id);
  const userId = pplsi?.authNPayload?.sub || '';
  const isUpgradable = !!upgrade && !isUpgradeSuccessful(userId);

  const navigate = useNavigate();

  return (
    <Card key={subscription?.id} classNames={['accounts-subscription', 'my-4']}>
      <Container flexDirection="row" flexbox alignItems="flex-start" classNames={['m-5']}>
        {isLoading ? (
          <SubscriptionPreviewSkeleton />
        ) : (
          <SubscriptionPreview
            logo={logo}
            price={price}
            productName={getFriendlyProductNameFromSubscription(subscription)}
            realmDomain={pplsi.realmDomain}
            subtitle={!isSubscriptionCancelled && `${string_table.PRODUCT_NEXT_BILL_DATE} ${paidToDate}`}
            showUpgradeButton={isUpgradable}
            onUpgradeClick={() => {
              navigate(`/payments/${subscription?.id}/upgrade`);
            }}
          />
        )}
      </Container>
      <div className="accounts-subscription-item__content p-5">
        <SubscriptionDetails isLoading={isLoading} subscription={subscription} />
      </div>
    </Card>
  );
};
