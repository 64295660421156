import React, { FC, useEffect, useState } from 'react';
import { Button, ListItem } from '@legalshield/adonis-ux-framework';
import { PhoneNumberResource, PhoneNumberType } from '@legalshield/frontend-commons/dist/sdk/profiles';

import { PhoneNumberEditor } from '../PhoneNumberEditor/PhoneNumberEditor';
import { PhoneNumberPresenter } from '../Presenters';
import { sortByPropAndPreferred } from '../../../utils/utils';

export interface PhoneNumberFieldProps {
  data: PhoneNumberPresenter;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loader: any;
}

export interface IPhoneNumberPresenter extends PhoneNumberResource {
  isNew: boolean;
}

export const PhoneNumberField: FC<PhoneNumberFieldProps> = ({ data, loader }: PhoneNumberFieldProps) => {
  const [indexBeingEdited, setIndexBeingEdited] = useState<number | null>(null);
  const [sortedNumbers, setSortedNumbers] = useState<IPhoneNumberPresenter[]>([]);
  const phoneNumbers = data.phoneNumbers;
  const version = data.version;

  useEffect(() => {
    setSortedNumbers(sortByPropAndPreferred(phoneNumbers, 'number', true));
  }, [phoneNumbers]);

  const emptyNumberObject = {
    isNew: true,
    number: '',
    preferred: false,
    type: PhoneNumberType.Cell,
    verified: false,
  };

  const addNewPhoneNumberRow = () => {
    loader.Blank();
    const newArray = sortedNumbers.concat(emptyNumberObject);
    setSortedNumbers(newArray);
    setIndexBeingEdited(newArray.length - 1);
  };

  return (
    <>
      <div className={'pnc-list-items'}>
        {sortedNumbers?.map((phone, index) => {
          return (
            <ListItem key={index} classNames={['pb-4', 'pt-4']}>
              <div
                id={`PN${index}`}
                data-testid={`PN${index}`}
                style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
              >
                <PhoneNumberEditor
                  index={index}
                  indexBeingEdited={indexBeingEdited}
                  number={phone.number}
                  type={phone.type}
                  preferred={phone.preferred}
                  version={version}
                  onEditPhoneNumber={setIndexBeingEdited}
                  loader={loader}
                />
              </div>
            </ListItem>
          );
        })}
      </div>
      <Button
        id="addPhoneNumberBtn"
        data-testid="addPhoneNumberBtn"
        classNames={['mt-3']}
        disabled={indexBeingEdited !== null}
        iconLeft={'edit_add_plus_circle_filled'}
        iconSize="small"
        iconColor="BRAND200"
        label={string_table.ADD_PHONE_NUMBER}
        onClick={() => addNewPhoneNumberRow()}
        variant="tertiary"
      />
    </>
  );
};
