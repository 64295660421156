import React, { FC } from 'react';
import { Container, Label, LinkContent, Text } from '@legalshield/adonis-ux-framework';

export interface SecurityItemProps {
  id?: string;
  title: string;
  description: string;
  handleEditClick: () => void | undefined;
}

export const SecurityItem: FC<SecurityItemProps> = ({ id, title, description, handleEditClick }) => (
  <Container
    id={id}
    flexbox
    flexDirection="row"
    alignItems="center"
    justifyContent="space-between"
    classNames={['py-4']}
  >
    <div>
      <Label text={title} classNames={['mb-2']} />
      {description && <Text textSize="large" text={description} />}
    </div>
    <div className="py-2">
      <LinkContent
        data-testid={`${title}EditButton`}
        classNames={['py-3', 'pr-3']}
        disabled={handleEditClick === undefined}
        text={string_table.EDIT}
        onClick={() => {
          handleEditClick();
        }}
      />
    </div>
  </Container>
);
