import React, { FC } from 'react';
import { Layout } from '../Layout/Layout';
import CardGrid, { renderGridCardButtons } from '../Card/CardGrid';
import URLs from '../../utils/url';
import Config from '../../config';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import memberPerksLogo from '../../images/memberPerksLogo.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import formsLogo from '../../images/formsLogo.svg';
export interface ResourcesButton {
  name: string;
  iconName?: string;
  imageSrc?: string;
  data: string;
  text?: string;
}

const forms: ResourcesButton = {
  data: URLs.getLSAppUrl(Config.currentEnv.apps.forms),
  imageSrc: formsLogo,
  name: 'Forms',
  text: string_table.RESOURCES_FORMS_DESCRIPTION,
};

const memberPerks: ResourcesButton = {
  data: 'https://legalshield.perkspot.com/login',
  imageSrc: memberPerksLogo,
  name: 'MemberPerks',
  text: string_table.RESOURCE_MEMBERSHIP_PERKS_DESCRIPTION,
};

// const faq: ResourcesButton = {
//   data: '/faq',
//   iconName: 'comm_bell',
//   name: 'FAQ',
//   text: string_table.RESOURCES_FAQ_DESCRIPTION,
// };

// const questions: ResourcesButton = {
//   data: '/notifications',
//   iconName: 'comm_bell',
//   name: 'Questions',
//   text: string_table.RESOURCES_QUESTIONS_DESCRIPTION,
// };

export const resourcesButton: ResourcesButton[] = [forms, memberPerks];

const Resources: FC = () => {
  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: string_table.RESOURCES_TITLE, link: '/resources' },
  ];

  return (
    <Layout crumbs={crumbs} title={string_table.RESOURCES_TITLE} subtitle={string_table.RESOURCES_DESCRIPTION}>
      <CardGrid>{renderGridCardButtons(resourcesButton)}</CardGrid>
    </Layout>
  );
};

export default Resources;
