import React, { FC, useEffect } from 'react';
import { useForm } from '@legalshield/adonis-ux-framework';

import { ButtonBar } from '../../Controls/ButtonBar';
import { PreferredNamePresenter } from '../Presenters';
import { useUpdatePreferredName } from '../../../hooks/useProfile';

export interface PreferredNameFieldProps {
  data: PreferredNamePresenter;
  onClose: (failure?: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loader: any;
}

export const PreferredNameField: FC<PreferredNameFieldProps> = ({ data, onClose, loader }: PreferredNameFieldProps) => {
  const newPreferredNameProp = data?.preferredName;
  const version = data?.version;
  const [formValues, formObject] = useForm(
    {
      preferredName: {
        errorMessage: string_table.PROFILE_FIELD_REQUIRED,
        label: `${string_table.PREFERRED} ${string_table.NAME}`,
        placeHolder: newPreferredNameProp ?? `${string_table.PREFERRED} ${string_table.NAME}`,
        required: true,
        type: 'text',
        value: newPreferredNameProp ?? '',
      },
    },
    null,
  );

  //is this still needed?
  useEffect(() => {
    const newValues = {
      ...formValues,
      preferredName: { ...formValues['preferredName'], hasChanged: true, value: newPreferredNameProp },
    };
    formObject.setValues(newValues);
  }, [newPreferredNameProp]);

  const { preferredName } = formValues;
  const { mutate, isLoading, isSuccess, isError } = useUpdatePreferredName(version);

  const updatePreferredName = async () => {
    mutate({
      preferredName: preferredName.value,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      loader.Success(string_table.ALERT_SUCCESS);
      onClose();
    } else if (isError) {
      loader.Error(string_table.ALERT_ERROR);
      onClose();
    } else if (isLoading) {
      loader.Loading();
    }
  }, [isSuccess, isError, isLoading]);

  return (
    <>
      <div id="preferredNameForm" data-testid="preferredNameForm">
        {formObject.renderForm()}
      </div>
      <ButtonBar
        alignLeft={true}
        id={'preferredNameBtnBar'}
        data-testid={'preferredNameBtnBar'}
        disableSave={!formObject.isValid || !formObject.hasChanged}
        onSave={() => updatePreferredName()}
      />
    </>
  );
};
