import { Accordion, Card, Divider, Text, useLoader } from '@legalshield/adonis-ux-framework';
import React, { useState, useEffect, Fragment, FC } from 'react';
import Settings from './Settings/Settings';
import Factors from './Factors/Factors';
import { useGetFactors } from '../../hooks/useIdentity';
import { Layout } from '../Layout/Layout';

interface MultifactorProps {
  operationEnabled?: (enabled: boolean) => void;
}

const Multifactor: FC<MultifactorProps> = ({ operationEnabled = null }) => {
  const [addingFactor, setAddingFactor] = useState(false);
  const loader = useLoader();

  const { data: mfa, isError, isLoading, isSuccess } = useGetFactors();

  useEffect(() => {
    if (isError) {
      loader.Error(string_table.MFA_FACTORS_ERRORFETCH);
    }
    if (isLoading) {
      loader.Loading();
    }
    if (isSuccess) {
      loader.Blank();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess, isLoading]);

  useEffect(() => {
    if (operationEnabled) {
      if (!(mfa && mfa.factors && mfa.factors.length > 0 && mfa.settings.multifactorType)) {
        operationEnabled(false);
      } else {
        operationEnabled(true);
      }
    }
  }, [mfa, operationEnabled]);

  if (pplsi.authNPayload.act && pplsi.authNPayload.act.sub) {
    return <p>Not available in impersonation mode</p>;
  }

  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: string_table.SECURITY_TITLE, link: '/security' },
    { label: string_table.MFA_INTRO_TITLE, link: '/mfa' },
  ];

  return (
    <Layout crumbs={crumbs} title={string_table.MFA_INTRO_TITLE} subtitle={string_table.MFA_INTRO_DESCRIPTION}>
      <Card classNames={'mb-4'}>
        <Card.Content>
          <Accordion.Root type="multiple">
            <Accordion.Item value="item-1" classNames={['mt-4']} border={false}>
              <Accordion.Trigger size="small" title={string_table.MFA_HELP} />
              <Accordion.Content>
                <Text classNames={['mt-4']} as="p" text={string_table.MFA_HELP1} />
                <Text classNames={['mt-4']} as="p" text={string_table.MFA_HELP2} />
                <Text classNames={['mt-4']} as="p" text={string_table.MFA_HELP3} />
                <Text classNames={['my-4']} as="p" text={string_table.MFA_HELP4} />
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        </Card.Content>
      </Card>
      {!addingFactor ? (
        <Settings
          multiFactorType={mfa?.settings ? mfa?.settings.multifactorType : false}
          factorCount={mfa?.factors?.length || 0}
          hasVerifiedFactor={mfa?.factors?.some((factor) => factor.verified) || false}
        />
      ) : (
        <Fragment />
      )}
      <Factors factors={mfa?.factors} setAddingFactor={setAddingFactor} />
    </Layout>
  );
};

export default Multifactor;
