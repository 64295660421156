import React, { FC, useState } from 'react';
import { Container, Label, LinkContent, Loader, Text, useLoader } from '@legalshield/adonis-ux-framework';

import BadgeIconControl from '../../Controls/BadgeIcon/BadgeIconControl';
import RealmUtility from '../../../utils/realm';
import {
  AddressPresenter,
  DateOfBirthPresenter,
  NamePresenter,
  EmailPresenter,
  PhoneNumberPresenter,
  PreferredNamePresenter,
} from '../Presenters';
import { AddressField, DateOfBirthField, EmailField, NameField, PhoneNumberField, PreferredNameField } from '..';
import { formatPhoneNumberWithDash, uppercaseFirstLetter } from '../../../helpers/stringFormatter';

import './EditContainer.scss';

type EditContainerData =
  | AddressPresenter
  | DateOfBirthPresenter
  | EmailPresenter
  | NamePresenter
  | PhoneNumberPresenter
  | PreferredNamePresenter;

export interface EditContainerProps {
  data?: EditContainerData;
  fieldName: string;
  editButton?: React.ReactNode;
}

export const EditContainer: FC<EditContainerProps> = ({ data, fieldName, editButton }: EditContainerProps) => {
  const loader = useLoader();
  const [isEditing, setIsEditing] = useState(false);
  const [isUser] = useState(RealmUtility.isUser());

  const MyComponents = {
    address: AddressField,
    dateOfBirth: DateOfBirthField,
    email: EmailField,
    name: NameField,
    phoneNumber: PhoneNumberField,
    preferredName: PreferredNameField,
  };

  const handleEditClick = () => setIsEditing(true);
  const handleCancelClick = () => setIsEditing(false);

  function onClose(failure: boolean) {
    if (!failure) {
      loader.Blank();
      handleCancelClick();
    }
  }

  function isAddressPresenter(data: EditContainerData): data is AddressPresenter {
    return (data as AddressPresenter).addressString !== undefined;
  }

  function isDateOfBirthPresenter(data: EditContainerData): data is DateOfBirthPresenter {
    return (data as DateOfBirthPresenter).formattedDateOfBirth !== undefined;
  }

  function isEmailPresenter(data: EditContainerData): data is EmailPresenter {
    return (data as EmailPresenter).emails !== undefined;
  }

  function isNamePresenter(data: EditContainerData): data is NamePresenter {
    return (data as NamePresenter).firstName !== undefined;
  }

  function isPhoneNumberPresenter(data: EditContainerData): data is PhoneNumberPresenter {
    return (data as PhoneNumberPresenter).phoneNumbers !== undefined;
  }

  function isPreferredNamePresenter(data: EditContainerData): data is PreferredNamePresenter {
    return (data as PreferredNamePresenter).preferredName !== undefined;
  }

  const address = isAddressPresenter(data) ? data.addressString : '';

  const emailAddresses = isEmailPresenter(data)
    ? data.emails?.map((email) => ({ preferred: email.preferred, value: email.address })) || []
    : [];

  const fullName = isNamePresenter(data) ? `${data.firstName} ${data.middleName} ${data.lastName}` : '';

  const formattedDateOfBirth = isDateOfBirthPresenter(data) ? data.formattedDateOfBirth : '';
  const formattedPhoneNumbers = isPhoneNumberPresenter(data)
    ? data.phoneNumbers?.map((phoneNumber) => ({
        preferred: phoneNumber.preferred,
        value: `${formatPhoneNumberWithDash(phoneNumber.number)} - ${uppercaseFirstLetter(phoneNumber.type)}`,
      }))
    : [];

  const addressDesc = string_table.PROFILE_ADDRESS_CHANGE_DISABLE.replace('%phone%', pplsi.phoneNumber);
  const nameDesc = string_table.PROFILE_NAME_CHANGE_DISABLE.replace('%phone%', pplsi.phoneNumber);
  const preferredName = isPreferredNamePresenter(data) ? data.preferredName : '';

  const displayData = {
    address: { desc: isUser ? addressDesc : null, title: string_table.PROFILE_ADDRESS, value: address },
    dateOfBirth: { title: string_table.PROFILE_BIRTH_DATE, value: formattedDateOfBirth },
    email: { title: string_table.PROFILE_EMAIL, value: emailAddresses },
    name: { desc: isUser ? nameDesc : null, title: string_table.PROFILE_LEGAL_NAME, value: fullName },
    password: { title: string_table.SECURITY_PASSWORD, value: string_table.SECURITY_NO_PASSWORD },
    phoneNumber: { title: string_table.PROFILE_PHONE_NUMBER, value: formattedPhoneNumbers },
    preferredName: { title: string_table.PROFILE_PREFERRED_NAME, value: preferredName },
  };

  const dataField = {
    description: displayData[fieldName].desc,
    title: displayData[fieldName].title,
    value: displayData[fieldName].value,
  };

  const Render = MyComponents[fieldName];

  const StringItem = () => (
    <div>
      <Text text={dataField.value.trim().length ? dataField.value : '-'} textSize="large" classNames={['mt-2']} />
      {dataField.description && (
        <Container classNames={['mt-2']}>
          <Text classNames={['edit-description']} text={dataField.description} textSize="small" as="p" />
        </Container>
      )}
    </div>
  );

  const NonStringItem = () => (
    <div
      data-testid={`${fieldName}DisplayFieldContainer`}
      className={'lsux-list-item__text lsux-list-item__text--left'}
    >
      <div>
        {dataField.value?.map((item, index: number) => {
          return (
            <Container key={`${index} desc`}>
              <Container flexbox style={{ alignItems: 'center' }}>
                <Text
                  textSize={'large'}
                  text={item.value}
                  classNames={['pt-2 pb-2 lsux-list-item__text-secondary ow-a']}
                />
                {item.preferred ? (
                  <BadgeIconControl
                    style={{ borderRadius: '5px', maxHeight: '24px', padding: '4px 10px 4px 6px' }}
                    classNames={['ml-4']}
                    icon={'interface_check'}
                    text={string_table.PREFERRED}
                    variant="primary"
                    small={true}
                    circle={false}
                    textWeight={'semibold'}
                  />
                ) : null}
              </Container>
              {dataField.description && (
                <Container classNames={['mt-2']}>
                  <Text classNames={['mt-2', 'edit-description']} text={dataField.description} />
                </Container>
              )}
            </Container>
          );
        })}
      </div>
    </div>
  );

  console.log(dataField.title, `dataFieldValue:${dataField.value}|`);

  return (
    <>
      <Container
        flexbox
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        classNames={{ 'my-4': !isEditing, 'mt-4 mb-0': isEditing }}
      >
        <div>
          <Label text={dataField.title} classNames={{ 'mb-2': !isEditing }} />
          {/* If the value is set, render it under the title */}
          {isEditing ? null : typeof dataField.value === 'string' ? <StringItem /> : <NonStringItem />}
        </div>
        {/* Render the Cancel or Edit button */}
        <div className="py-2">
          {editButton ? (
            editButton
          ) : isEditing ? (
            <LinkContent
              data-testid={`${fieldName}CancelButton`}
              classNames={['py-3', 'pr-3']}
              text={string_table.CANCEL}
              onClick={() => {
                handleCancelClick();
              }}
            />
          ) : (
            <LinkContent
              data-testid={`${fieldName}EditButton`}
              classNames={['py-3', 'pr-3']}
              disabled={isEditing || dataField.description}
              text={string_table.EDIT}
              onClick={() => {
                handleEditClick();
              }}
            />
          )}
        </div>
      </Container>
      {isEditing && (
        <div id={`${fieldName}EditFieldContainer`} data-testid={`${fieldName}EditFieldContainer`} className={'bb-1'}>
          <Loader loaderState={loader.loaderState} />
          <Render loader={loader} onClose={onClose} data={data} />
        </div>
      )}
    </>
  );
};
