import {
  Api as PaymentMethodsApi,
  PaymentMethodResource,
} from '@legalshield/frontend-commons/dist/sdk/payment-methods';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const paymentMethodsApi = new PaymentMethodsApi();

const identityId = pplsi?.authNPayload?.sub;

const getPaymentMethodsByIdentityId = async (): Promise<PaymentMethodResource[]> => {
  const { data: paymentMethods } = await paymentMethodsApi.v1.paymentMethodsGetPaymentMethods(identityId, {
    withCredentials: true,
  });

  const activePaymentMethods = paymentMethods.filter((paymentMethod: PaymentMethodResource) => !paymentMethod.deleted);
  return activePaymentMethods;
};

const getPaymentMethodByIdentityId = async (paymentMethodId: string): Promise<PaymentMethodResource> => {
  if (!paymentMethodId) return null;

  const { data: paymentMethod } = await paymentMethodsApi.v1.paymentMethodsGetPaymentMethod(
    identityId,
    paymentMethodId,
    {
      withCredentials: true,
    },
  );

  return paymentMethod;
};

export const useGetPaymentMethod = (paymentMethodId: string): UseQueryResult<PaymentMethodResource> => {
  return useQuery(['payment-method', paymentMethodId], () => getPaymentMethodByIdentityId(paymentMethodId));
};

export const useGetPaymentMethods = (): UseQueryResult<PaymentMethodResource[]> => {
  return useQuery(['payment-methods'], getPaymentMethodsByIdentityId);
};
