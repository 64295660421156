// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addressListItem .lsux-list-item__text-secondary {
  max-width: 50%;
}

.ow-a {
  overflow-wrap: anywhere;
}`, "",{"version":3,"sources":["webpack://./src/components/Profile/EditContainer/EditContainer.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,uBAAA;AACF","sourcesContent":[".addressListItem .lsux-list-item__text-secondary {\n  max-width: 50%;\n}\n\n.ow-a {\n  overflow-wrap: anywhere;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
