import React, { FC, useEffect, useState } from 'react';
import { Button, Checkbox, Container, FormField, LinkContent, Text, useForm } from '@legalshield/adonis-ux-framework';
import { RegEx } from '@legalshield/frontend-commons';
import { RemoveEmailRequest, UpdateEmailRequest } from '@legalshield/frontend-commons/dist/sdk/profiles';

import BadgeIconControl from '../../Controls/BadgeIcon/BadgeIconControl';
import { useAddEmail, useRemoveEmail, useUpdateEmail } from '../../../hooks/useProfile';
import { waitInSeconds } from '../../../utils/utils';

import './EmailEditor.scss';

export interface EmailEditorProps {
  index: number;
  indexBeingEdited?: number | null;
  address: string;
  onEditEmail(index?: number): void;
  onRemoveNewEmail(): void;
  preferred: boolean;
  version?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loader: any;
}

export const EmailEditor: FC<EmailEditorProps> = ({
  index,
  indexBeingEdited,
  address,
  onEditEmail,
  preferred,
  version,
  loader,
}: EmailEditorProps) => {
  const isReadOnly = index !== indexBeingEdited;
  const [isEditing, setIsEditing] = useState(!isReadOnly);
  const [isEmailPreferred, setIsEmailPreferred] = useState(preferred);
  const [isNewRow] = useState(address === '');

  const {
    mutate: updateMutate,
    isSuccess: updateIsSuccess,
    isError: updateIsError,
    isLoading: updateIsLoading,
  } = useUpdateEmail(version);
  const {
    mutate: addMutate,
    isSuccess: addIsSuccess,
    isError: addIsError,
    isLoading: addIsLoading,
  } = useAddEmail(version);
  const {
    mutate: removeMutate,
    isSuccess: removeIsSuccess,
    isError: removeIsError,
    isLoading: removeIsLoading,
  } = useRemoveEmail(version);

  const [formValues, formObject] = useForm({}, null);

  useEffect(() => {
    formObject.setValues({
      email: {
        errorMessage: string_table.PROFILE_EMAIL_ERROR,
        isValid: address != '',
        label: string_table.PROFILE_EMAIL,
        readOnly: isReadOnly,
        regEx: RegEx.emailRegex,
        required: true,
        type: 'text',
        value: address || '',
      },
    });
  }, [address, isReadOnly, preferred]);

  useEffect(() => {
    if (addIsError || removeIsError || updateIsError) {
      loader.Error(string_table.ALERT_ERROR);
    } else if (addIsSuccess || removeIsSuccess || updateIsSuccess) {
      loader.Success(string_table.ALERT_SUCCESS);
    }
    if (addIsLoading || removeIsLoading || updateIsLoading) {
      loader.Loading();
    }
  }, [
    addIsError,
    removeIsError,
    updateIsError,
    addIsLoading,
    removeIsLoading,
    updateIsLoading,
    addIsSuccess,
    removeIsSuccess,
    updateIsSuccess,
  ]);

  const addEmail = async () => {
    addMutate({
      address: formValues['email'].value,
      preferred: isEmailPreferred,
      verified: false,
    });
    onEditEmail(null);
    setIsEditing(false);
    await waitInSeconds(5);
    loader.Blank();
  };

  const updateEmail = async () => {
    const updateEmailReq: UpdateEmailRequest = {
      address: formValues['email'].value,
      preferred: isEmailPreferred,
    };

    updateMutate({ oldAddress: address, req: updateEmailReq });

    onEditEmail(null);
    setIsEditing(false);
    await waitInSeconds(5);
    loader.Blank();
  };

  const deleteEmail = async () => {
    removeMutate({ address: formValues['email'].value } as RemoveEmailRequest);

    onEditEmail(null);
    setIsEditing(false);
    await waitInSeconds(5);
    loader.Blank();
  };

  // const cancelEdit = () => {
  //   formObject.setValues({ ...formValues, email: { ...formValues['email'], value: address } });
  //   onEditEmail(null);
  //   setIsEditing(false);
  // };

  const editEmail = () => {
    loader.Blank();
    onEditEmail(index);
    setIsEditing(true);
  };

  return (
    <>
      {!isEditing && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <Text textSize={'large'} text={address} classNames={['pt-2 pb-2 lsux-list-item__text-secondary ow-a']} />
              {preferred ? (
                <BadgeIconControl
                  style={{ borderRadius: '5px', maxHeight: '24px', padding: '4px 10px 4px 6px' }}
                  classNames={['ml-4']}
                  icon={'interface_check'}
                  text={string_table.PREFERRED}
                  variant="primary"
                  small={true}
                  circle={false}
                  textWeight={'semibold'}
                />
              ) : null}
            </div>
            <LinkContent
              data-testid="emailEditOption"
              classNames={['py-3', 'px-4']}
              linkContentStyle="link"
              text={string_table.EDIT}
              disabled={indexBeingEdited != null}
              onClick={() => {
                editEmail();
              }}
            />
          </div>
        </>
      )}
      {isEditing && (
        <>
          <Container id="emailForm" data-testid="emailForm">
            {formObject.renderForm()}
            <FormField id="emailIsPreferred" required={true}>
              <Checkbox
                id="emailIsPreferred"
                name="emailIsPreferred"
                checked={isEmailPreferred}
                rightLabel={string_table.PROFILE_SET_AS_PREFERRED}
                onCheckedChange={setIsEmailPreferred}
                disabled={preferred}
                classNames={['mt-4']}
              />
            </FormField>
            <Container id={'emailBtnBar'} data-testid={'emailBtnBar'} classNames={['pt-6']}>
              <Container flexbox>
                <Button
                  classNames={['mr-2']}
                  style={{ boxShadow: '0 0 0 1.5px red', color: 'red' }}
                  textSize="small"
                  onClick={() => deleteEmail()}
                  disabled={preferred || isNewRow}
                  variant="secondary"
                  label={string_table.REMOVE}
                />
                <Button
                  classNames={['ml-2']}
                  onClick={() => (isNewRow ? addEmail() : updateEmail())}
                  label={string_table.SAVE}
                  disabled={!formObject.isValid}
                  variant="primary"
                  textSize="small"
                />
              </Container>
            </Container>
          </Container>
        </>
      )}
    </>
  );
};
