import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Divider, useLoader } from '@legalshield/adonis-ux-framework';

import RealmUtility from '../../utils/realm';
import { Layout } from '../Layout/Layout';
import { SecurityItem } from './SecurityItem';
import { useGetFactors, useGetIdentity } from '../../hooks/useIdentity';
import { useGetProfile } from '../../hooks/useProfile';

const Security: FC = () => {
  const loader = useLoader();
  const navigate = useNavigate();
  const [isGroupAdmin] = useState(RealmUtility.isGroupAdmin());
  const [isPartner] = useState(RealmUtility.isPartner());

  const { isLoading: isProfileLoading, isSuccess: isProfileSuccess, isError: isProfileError } = useGetProfile();

  const {
    data: sData,
    isLoading: isSecurityLoading,
    isSuccess: isSecuritySuccess,
    isError: isSecurityError,
  } = useGetIdentity();

  const { data: mfa, isLoading: isFactorsLoading } = useGetFactors();

  const emailsString: string = sData?.emails ? sData?.emails.join(', ') : '-';

  const mfaText = isFactorsLoading
    ? '-'
    : mfa?.settings?.multifactorType === false
      ? string_table.SECURITY_MFA_OFF
      : string_table.SECURITY_MFA_ON;

  useEffect(() => {
    if (isProfileError || isSecurityError) {
      loader.Error(string_table.ALERT_ERROR);
    }
    if (isProfileLoading || isSecurityLoading) {
      loader.Loading();
    }
    if (isSecuritySuccess && isProfileSuccess) {
      loader.Blank();
    }
  }, [isProfileLoading, isProfileError, isProfileSuccess, isSecuritySuccess, isSecurityError, isSecurityLoading]);

  const redirect = (path: string) => {
    loader.Blank();
    navigate(`${path}`);
  };

  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: string_table.SECURITY_TITLE, link: '/security' },
  ];

  return (
    <Layout crumbs={crumbs} title={string_table.SECURITY_TITLE} subtitle={string_table.SECURITY_DESCRIPTION}>
      <Card>
        <Card.Content>
          <div className="px-4 py-1">
            <SecurityItem
              id="emailContainer"
              title={string_table.SECURITY_EMAIL_TITLE}
              description={emailsString ?? '-'}
              handleEditClick={undefined}
            />
            <Divider />
            {(isPartner || isGroupAdmin) && !sData?.hasPassword ? (
              <>
                <SecurityItem
                  id="pswrdNewPartnerContainer"
                  title={string_table.SECURITY_PASSWORD}
                  description={string_table.SECURITY_NO_PASSWORD}
                  handleEditClick={() => redirect('/security/password')}
                />
                <Divider />
              </>
            ) : (
              <>
                <SecurityItem
                  id="pswrdContainer"
                  title={string_table.SECURITY_PASSWORD}
                  description="••••••••"
                  handleEditClick={() => redirect('/security/password')}
                />
                <Divider />
              </>
            )}
            <SecurityItem
              id="mfaContainer"
              title={string_table.SECURITY_MFA_VERIFICATION}
              description={mfaText}
              handleEditClick={() => redirect('/mfa')}
            />
          </div>
        </Card.Content>
      </Card>
    </Layout>
  );
};

export default Security;
