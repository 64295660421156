import React, { FC } from 'react';
import { Container, Link, Text } from '@legalshield/adonis-ux-framework';

import { formatPhoneNumberWithDash } from '../../../../helpers/stringFormatter';

import './CallForQuestions.scss';

export interface CallForQuestionsProps {
  phoneNumber: string;
}

const CallForQuestions: FC<CallForQuestionsProps> = ({ phoneNumber }) => (
  <Container flexbox flexDirection="row" justifyContent="center" classNames={['mt-5']}>
    <Text textSize="small" text={string_table.LINK_MEMBERSHIP_CALL} classNames={['tel_text-color']} />
    <Link
      textSize="small"
      classNames={['lsux-text', 'lsux-text--small', 'tel_text-color', 'ml-2']}
      href={`tel:${phoneNumber}`}
      text={formatPhoneNumberWithDash(phoneNumber)}
    />
  </Container>
);

export default CallForQuestions;
