import React, { FC } from 'react';
import { Accordion, Card, TabsContent, TabsList, TabsRoot, TabsTrigger } from '@legalshield/adonis-ux-framework';

import { Layout } from '../Layout/Layout';

const LS_FAQ_54_TITLE = 'How do I submit a complaint about a LegalShield Independent Associate?';
const LS_FAQ_1 = `For over 50 years, LegalShield has made it affordable and easy for its members to connect with its network of vetted, dedicated law firms across the United States and parts of Canada that average 22 years of experience. (LegalShield provides access to legal services offered by a network of provider law firms to its members through membership-based participation.)`;
const LS_FAQ_10 = `It depends on your legal matter/situation. When you contact your dedicated provider law firm and explain your legal situation, they will have a provider lawyer call you. Once you speak directly with the assigned lawyer, they will be able to determine what legal assistance is needed for your specific situation.`;
const LS_FAQ_10_TITLE = 'What level of contact will I have with a lawyer if I am a LegalShield member?';
const LS_FAQ_11 = `You may not work with the same lawyers if you have legal issues in totally different areas of law. However, you will work with the same provider law firm for your covered legal needs.`;
const LS_FAQ_11_TITLE = 'Will I work with the same lawyer each time I need help?';
const LS_FAQ_12 = `We offer a variety of personal and business membership options. To find the membership that is right for you, please visit www.legalshield.com to sign up on your own. If you would like further assistance or want to ask specific questions, please contact us at (833)-951-2754 between 7-7 CST Monday-Friday and one of our team members would be happy to assist you.`;
const LS_FAQ_12_TITLE = 'How do I start a membership?';
const LS_FAQ_13 = [
  'We accept the following payment methods:',
  ['Monthly bank draft', 'Annual bank draft', 'Monthly credit card', 'Annual credit card'],
  'Due to the subscription nature of our business, we do not accept prepaid credit cards.',
];
const LS_FAQ_13_TITLE = 'How can I pay for my membership?';
const LS_FAQ_14 = `You can use your plan as soon as your membership application is reviewed and your account is set up. The review process takes 12-24 business hours (or less) from the moment you purchase your plan. Once accepted, you can connect with your provider law firm within 8 business hours once your request is submitted.`;
const LS_FAQ_14_TITLE = 'How long after I join can I use the plan?';
const LS_FAQ_15 = `When you need legal help that does not fall under the standard benefits, you may be able to work with your provider law firm for a discount off their standard hourly rates. The total cost depends on factors such as the area of law, your geographic location and your lawyer’s hourly rate. What we can say for sure is that if you are a member and work with your provider law firm under the discounted rates, you could save as a LegalShield member over hiring a lawyer directly. Costs are subject to change depending on the legal matter and its circumstances.`;
const LS_FAQ_15_TITLE = 'If the basic plan benefits don’t cover my specific situation, how much will it cost me?';
const LS_FAQ_16 = [
  'Our Personal Plans are really family plans and covers:',
  [
    'The member (The person taking out the membership)',
    'The member’s spouse',
    'Never-married dependent children of the member or member’s spouse who are under 26 and living at home (on select plans)',
    'Children under age 18 for whom the member or member’s spouse is the legal guardian',
    'Full-time never-married students under 26 years old if the student is a dependent of the member or member’s spouse (on select plans)',
    'Any dependent child, regardless of age, who is incapable of sustaining employment because of mental or physical disability and who is chiefly dependent on the member or member’s spouse for support.',
  ],
  'Note: Certain benefits may be available for the member and/or the member’s spouse only. Be sure to check the specific contract in your state.',
];
const LS_FAQ_16_TITLE = 'Who is covered under the Personal Plans?';
const LS_FAQ_17 = `As a LegalShield Member, you get access to a variety of legal services, in general, including: advice on an unlimited number of personal issues, letters or calls made on your behalf, contract and document review (starting at up to 15 pages each) and more. Review a plan contract for complete terms, coverage, amounts, conditions, eligibility, and exclusions.`;
const LS_FAQ_17_TITLE = 'What is covered under my Personal LegalShield Membership?';
const LS_FAQ_18 = `Yes, generally your membership covers Wills for you, your spouse and eligible family dependents (on select plans). Please check your specific membership for exact terms and conditions.`;
const LS_FAQ_18_TITLE = 'Does my personal membership cover Wills?';
const LS_FAQ_19 = `A Trust can be established under the preferred member discount if offered by your provider law firm.`;
const LS_FAQ_19_TITLE = 'Does my membership cover Trusts?';
const LS_FAQ_1_TITLE = 'What is LegalShield?';
const LS_FAQ_2 = `Instead of paying a lawyer by the hour, you pay a small membership fee with LegalShield and have access to a dedicated provider law firm in your state for the legal services membership you select but without a huge bill. Prices and coverage vary depending on the membership services you select.`;
const LS_FAQ_20 = `Representation for an uncontested divorce, separation or annulment is included in your standard plan benefits if you meet the requirements (see a plan). Your provider law firm will manage the process for you.
For a contested divorce, you can call your provider law firm for legal advice, if you want your provider law firm to handle the negotiation of a settlement agreement or represent you at trial, you may hire the provider law firm and take advantage of your plan’s discount from the provider law firm’s standard hourly rate benefit.`;
const LS_FAQ_20_TITLE = 'Will LegalShield handle uncontested divorces? What about contested divorces?';
const LS_FAQ_21 = `Yes, your membership covers tax audits.`;
const LS_FAQ_21_TITLE = 'Does my membership cover tax audits?';
const LS_FAQ_22 = `A LegalShield membership provides consultation and a discount on other legal services related to immigration issues. Please contact your provider law firm to discuss your benefits and options.`;
const LS_FAQ_22_TITLE = 'Does my membership cover immigration?';
const LS_FAQ_23 = `Yes, a LegalShield membership provides assistance with speeding tickets.`;
const LS_FAQ_23_TITLE = 'Does my membership cover speeding tickets?';
const LS_FAQ_24 = `Yes. Like most legal issues, you can always call the provider law firm for advice about child custody. You can also have child custody-related documents reviewed and could have a phone call made and a letter written on your behalf.

If you need more robust representation, you can retain a provider lawyer to handle child custody negotiations or court cases for a discount off their standard hourly fees. No matter the circumstances, a LegalShield membership can save you money on legal services to help you with child custody issues.`;
const LS_FAQ_24_TITLE = 'Does LegalShield help with child custody?';
const LS_FAQ_25 = [
  'All LegalShield memberships include benefits that allow your lawyer to represent you in court at substantial savings over hiring a lawyer directly. If you have a more complex situation, you can retain a provider lawyer to represent you in court at a discount off their standard hourly fees. To utilize this benefit the provider lawyer must be retained.',

  'For specific information on which plan benefits apply to your circumstances, we encourage you to review our coverage detail pages or contact customer support at (833)-951-2754 for an answer.',
  ['Personal Plans Coverage details', 'Small Business Plan Coverage details', 'Trial Defense Supplement details'],
];
const LS_FAQ_25_TITLE = 'Will LegalShield represent you in court?';
const LS_FAQ_26 = [
  'One way or the other, LegalShield plans cover most legal situations. You can call your provider law firm for advice on any legal issue, including criminal cases. Also, your lawyer can review documents and provide feedback related to criminal cases for no additional fee.',

  'Should you need a lawyer to represent you in criminal court, you can retain a provider lawyer for a discount off their standard hourly rates.',

  'If you’ve been arrested or otherwise have concerns about a criminal case, we strongly suggest becoming a member today . You can be on the phone with a provider lawyer to get the advice you need to reduce stress and figure out the next best steps to protect your rights and freedom. Please note that memberships cannot be activated if the member is incarcerated.',
];
const LS_FAQ_26_TITLE = 'Does LegalShield handle criminal cases?';
const LS_FAQ_27 = `Our dedicated LegalShield Member Services Team is ready to help. Please get in touch with them at (800)-654-7757, via chat at www.legalshield.com between 7-7 CST Monday-Friday, or via our LegalShield app, where assistance from our Member Services Team and your Provider Law Firm is just a tap of the app away. A member of our team will be happy to assist you.`;
const LS_FAQ_27_TITLE = 'How do I contact LegalShield Member Services for support?';
const LS_FAQ_28 = [
  'You can find your membership number in your welcome email after signing up for the services or by speaking with one of our agents on the phone. You can also find it after logging into your account via accounts.legalshield.com or the LegalShield mobile app.',

  'If you need assistance locating it, contact our LegalShield Member Services at (800)-654-7757, via chat at www.legalshield.com between 7-7 CST Monday-Friday or via our LegalShield app.',
];
const LS_FAQ_28_TITLE = 'Where can I find my LegalShield membership number?';
const LS_FAQ_29 = `Yes. LegalShield and IDShield are two separate memberships. You will have a unique membership number for both.`;
const LS_FAQ_29_TITLE = 'I subscribe to both LegalShield and IDShield. Will I have two different Membership Numbers?';
const LS_FAQ_2_TITLE = 'What is a legal subscription service (or pre-paid legal plan), and how does it work?';
const LS_FAQ_3 = `Yes. Pre-Paid Legal Services, Inc. began doing business as LegalShield on September 10, 2011. While the name we do business as has changed, we’re the same organization with over 50 years in business and offer an even more robust suite of services.`;
const LS_FAQ_30 = `After you have signed up for a LegalShield legal plan, you will receive a welcome email. It will contain the instructions to log in to accounts.legalshield.com and download the LegalShield mobile app. Or you can call your provider law firm’s 800 number to open an intake for your legal matter.`;
const LS_FAQ_30_TITLE = 'How do I gain access to my legal plan benefits?';
const LS_FAQ_31 = `You can update your billing info by contacting Member Services at 1-800-654-7757 from 7 a.m.-7 p.m. CST Monday through Friday, or online at accounts.legalshield.com. Just log in, go to Account Settings, and select the plan you’d like to update. Then scroll down to Member Payment Information and select Edit.`;
const LS_FAQ_31_TITLE = 'How do I update my billing info?';
const LS_FAQ_32 = `Access after-hours legal consultation for covered legal emergencies such as an arrest or detainment, if you’re seriously injured in an automobile accident, or if you’re served with a warrant. Just call 1-877-825-3797 to speak to a representative 24 hours a day, 7 days a week. You can also log in to the LegalShield mobile app and select Emergency Legal Access to make your call.`;
const LS_FAQ_32_TITLE = 'How can I access my legal team in an emergency?';
const LS_FAQ_33 = `No. While we’ve spent a lot of time making the app a seamless user experience, you don’t have to use it to access your benefits. After you purchase a plan, you’ll get an email that will include a toll-free number you can call to take advantage of your member benefits, you can call the customer care number at the top of our website or log into your online portal.`;
const LS_FAQ_33_TITLE = 'Do I have to use the app to use my plan?';
const LS_FAQ_34 = `The membership fee is significantly less than paying the standard hourly rate at a traditional law firm. Also, instead of ignoring a situation where you’ve been wronged, you can affordably and easily consult a lawyer.`;

const LS_FAQ_34_TITLE = 'How can I save money with a LegalShield membership?';
const LS_FAQ_35 = [
  'LegalShield is a pre-paid monthly subscription service, and our members have the right to cancel at any time. Per our company policy, all cancellation requests must be provided in writing. The easiest way to cancel is by emailing us at support@legalshieldcorp.com with your name, membership number, phone number, and your request to cancel the membership.',

  'For annual memberships, you may cancel any time after your first 12 months. If you would like to speak to someone about options to maintain your membership, please call as soon as possible to keep any pre-trial hours or other benefits you may have accumulated at (844)-714-8494 between 7-7 CST Monday-Friday, and a member of our team would be happy to help.',
];
const LS_FAQ_35_TITLE = 'What is your cancellation policy?';
const LS_FAQ_36 = `You may request reinstatement anytime by contacting your LegalShield Associate or Member Services at 1-800-654-7757. Member Services is available from 7 a.m. – 7 p.m. CST Monday-Friday.`;
const LS_FAQ_36_TITLE = 'How do I reinstate my membership?';
const LS_FAQ_37 = `To submit a complaint, please submit our Attorney Service Concern Form at https://legalshield.typeform.com/to/pSnMnF, and a team member will reach out to you directly. We look forward to assisting you.`;
const LS_FAQ_37_TITLE = 'How do I submit a complaint about my Provider Law Firm?';
const LS_FAQ_38 = `When a referral is needed for legal representation, we require the member’s provider law firm to initiate that process on the member’s behalf. Depending on the legal matter at hand, the referral process typically takes 3-5 business days but can occasionally take longer than expected. The referral lawyer will reach out to you directly.`;
const LS_FAQ_38_TITLE = 'How does your referral process work?';
const LS_FAQ_39 = `Our dedicated LegalShield Member Services Team is ready to help. Please get in touch with them at (800)-654-7757, via chat at www.legalshield.com between 7-7 CST Monday-Friday, or via our LegalShield app. A member of our team will be happy to assist you.`;
const LS_FAQ_39_TITLE = 'How do I get technical support?';
const LS_FAQ_3_TITLE = 'Is LegalShield the same as Pre-Paid Legal Services, Inc.?';
const LS_FAQ_4 = [
  'LegalShield is a pre-paid monthly subscription service that operates in a highly regulated industry monitored by the FTC. Our mission for more than 50 years has been to protect and empower people with the tools and services needed to affordably live a just and secure life.',
  'While we have a variety of sales channels, we believe in helping our independent associates find empowerment as business owners. While there can be negative stigmas around MLM sales channels, we assure you that our products and services are real. Today, we continue to harness the power of technology to empower over 4.5 million individuals and 140,000 businesses across the United States and parts of Canada to take their future into their own hands through our tools, services, and partnerships.',
];
const LS_FAQ_40 = `Please ensure that the OS on your device and your LegalShield app is updated to the latest version. For further assistance, please contact Member Services at (800)-654-7757 or via chat at www.legalshield.com between 7-7 CST Monday-Friday, and a member of our team will be happy to help.`;
const LS_FAQ_40_TITLE = 'How do I get support for the LegalShield app?';
const LS_FAQ_41 = `If you have already requested to reset your password but didn’t receive an email, please ensure that you have not previously unsubscribed from our emails, as this prevents password reset emails from being received. If you need further assistance, please contact Member Services at (800)-654-7757 or via chat at www.legalshield.com between 7-7 CST Monday-Friday, and a member of our team will be happy to help.`;
const LS_FAQ_41_TITLE = 'I requested to reset my password; why didn’t I receive an email?';
const LS_FAQ_42 = `The user may have multiple profiles that need to be linked by our Member Service Team. Please get in touch with them for further assistance at (800)-654-7757, via chat at www.legalshield.com between 7-7 CST Monday-Friday, or via our LegalShield app, and a member of our team will be happy to help.`;
const LS_FAQ_42_TITLE = 'Why can’t I see my memberships when I log in?';
const LS_FAQ_43 = `The user may have multiple profiles that need to be linked by our Member Service Team. Please get in touch with them for further assistance at (800)-654-7757, via chat at www.legalshield.com between 7-7 CST Monday-Friday, or via our LegalShield app, and a member of our team will be happy to help.`;
const LS_FAQ_43_TITLE = 'I am already a member. Why am I being asked to sign up when I try to log in?';
const LS_FAQ_44 = `To resolve the issue, please go to the platform that you cannot log in and:

Clear your cache/cookies/history
Close all browser windows, etc.
Mobile users—manually enter your credentials. Don’t use auto fillers if you are having issues logging in
If you need further assistance, please contact Member Services at (800)-654-7757, via chat at www.legalshield.com between 7-7 CST Monday-Friday, or via our LegalShield app, and a member of our team will be happy to help.`;
const LS_FAQ_44_TITLE = 'Why can I only log into my account via the LegalShield app OR the website?';
const LS_FAQ_45 = [
  'To resolve the issue, please:',
  [
    'Ensure that the OS on your device is updated to the latest version',
    'Ensure that your LegalShield app is updated to the latest version',
    'Ensure that you have a good data connection or Wi-Fi connection',
  ],
  'If this feature crashes on a specific step, such as uploading documents to your intake, please note: There is a size limitation of 20MB for uploading supporting documents. For larger documents, please work with your provider directly to submit those after you start your intake.',
  'If you are still having issues after completing the following steps, please submit a Technical Support Request at https://legalshield.typeform.com/to/NrIR48, and a member of our team will be happy to assist you.',
];
const LS_FAQ_45_TITLE = `Why is the 'Request A Consultation Feature' freezing or crashing?`;
const LS_FAQ_46 = `Yes, there is a size limitation of 20MB for uploading supporting documents. For larger documents, please work with your provider directly to submit those after you start your intake.`;
const LS_FAQ_46_TITLE = 'Is there a size limit for uploading supporting documents with my intake?';
const LS_FAQ_47 = `The invited users may have multiple profiles that need to be linked by our Member Service Team. 
Please get in touch with them for further assistance at 
(800)-654-7757, via chat at www.legalshield.com between 7-7 CST Monday-Friday, or via our LegalShield app, and a member of our team will be happy to help.`;
const LS_FAQ_47_TITLE = 'Why can’t I add my Spouse or Dependents?';
const LS_FAQ_48 = `You can start by trying your Membership Number. If that doesn’t work, the invited users may have multiple profiles that need to be linked by our Member Service Team. Please get in touch with them for further assistance at (800)-654-7757, via chat at www.legalshield.com between 7-7 CST Monday-Friday, or via our LegalShield app, and a member of our team will be happy to help.`;
const LS_FAQ_48_TITLE = 'My Spouse or Dependent didn’t get the Account Code requested to log in?';
const LS_FAQ_49 = `If you have moved out of state and need to update your address, please get in touch with Member Services for further assistance at (800)-654-7757, via chat at www.legalshield.com between 7-7 CST Monday-Friday, or via our LegalShield app, and a member of our team will be happy to help.`;
const LS_FAQ_49_TITLE = 'I need to update my address. Why can’t I update my State?';
const LS_FAQ_4_TITLE = 'Is LegalShield an MLM?';
const LS_FAQ_5 = `Personal Plans from LegalShield cover most personal legal issues. Most day-to-day legal issues are covered by the standard member benefits with no additional fees. If your specific personal legal need is more unique and requires additional legal assistance beyond the standard benefits, your provider law firm can help you at a discount off their standard hourly rates. If you still have questions on coverage, call us at (833)-951-2754. LegalShield provider law firms do not assist with International Law, Military Law and Tribal Law.`;
const LS_FAQ_50 = `Our mission for more than 50 years has been to protect and empower people with the tools and services needed to affordably live a just and secure life. Independent associates’ market and sell memberships. They understand and are passionate about our products and services. They share them with others, help them set up their memberships, and accompany them on their journey to legal representation and security—all while creating a business to earn extra income. This business model is not for everyone. Growing your business takes hard work. Success is never easy.`;
const LS_FAQ_50_TITLE = 'Who are LegalShield Independent Associates?';
const LS_FAQ_51 = `To locate a LegalShield Independent Associate near you, please visit https://www.wearelegalshield.com.`;
const LS_FAQ_51_TITLE = 'How do I find a LegalShield Independent Associate in my area?';
const LS_FAQ_52 = `Thanks for considering LegalShield. Our dedicated team is available to answer all your questions about becoming a LegalShield Independent Associate. You can reach our Associate Services Team at (580)-436-7424 from 7-7 CST Monday – Friday, and a member of our team can provide all those details for you or visit www.legalshield.com/become-an-associate.`;
const LS_FAQ_52_TITLE = 'How do I become a LegalShield Independent Associate?';
const LS_FAQ_53 = `Thank you for your interest in the LegalShield Opportunity. Our start-up cost is $99. To learn more about joining the team, please visit www.legalshield.com/become-an-associate. Or if you have specific questions, you can contact our Associate Services Team at (580)-436-7424 between 7-7 CST Monday-Friday and a dedicated team member would be happy to provide further details.`;
const LS_FAQ_53_TITLE = 'How much does it cost to get started with the LegalShield Opportunity?';
const LS_FAQ_54 = `To submit a complaint, please submit our Associate Concern Form at https://legalshield.typeform.com/to/TQ3Yos, and a member of our team will reach out to you. We look forward to assisting you.`;
const LS_FAQ_5_TITLE = 'Will the membership really cover my specific legal issue?';
const LS_FAQ_6 = `Most pre-existing situations can receive consultation and a discount from the provider law firm’s standard hourly rate. There are exclusions and exceptions, but your provider law firm can provide advice and possibly more on most pre-existing situations. Current exclusions and exceptions include:
• Any civil action, motor vehicle tickets or charges relating to an event that happened before starting your membership.
• Any IRS inquiry, investigation, or notice received before the start of your membership.`;
const LS_FAQ_6_TITLE = 'Does the LegalShield membership cover pre-existing situations?';
const LS_FAQ_7 = `With a LegalShield membership, you don’t have to. There’s no countless hours of researching. No stressing, no guessing. LegalShield takes the vetting and selection of law firms very seriously. You can be assured that all the dedicated provider law firms are experienced. They actually have an average of 22 years of experience!`;
const LS_FAQ_7_TITLE = 'How do I choose a lawyer?';
const LS_FAQ_8 = `Each state and some parts of Canada each have their own dedicated law firm. Members are to contact their dedicated provider law firm for membership services.`;
const LS_FAQ_8_TITLE = 'What is LegalShield’s service area, and are there provider law firms in my area?';
const LS_FAQ_9 = `As a member you can contact your provider law firm right from the LegalShield mobile app or call them directly. A customer service representative will ask you what type of legal matter you have, verify your address and phone numbers, gather information about your legal matter, and forward your information to the assigned provider lawyer.`;
const LS_FAQ_9_TITLE = 'How do I contact my Provider Law Firm?';

const IDS_FAQ_TITLE_1 = 'How do thieves get my personal information?';
const IDS_FAQ_TITLE_10 = 'Is identity theft the same as credit card fraud?';
const IDS_FAQ_TITLE_11 = 'If I have a bad credit score, do I need to worry about identity theft?';
const IDS_FAQ_TITLE_12 = 'What is the dark web?';
const IDS_FAQ_TITLE_13 = 'What is credit or debit card fraud?';
const IDS_FAQ_TITLE_14 = 'How is a credit score different from a credit report?';
const IDS_FAQ_TITLE_15 = 'If there is an account on my credit report I did not open, does that prove identity theft?';
const IDS_FAQ_TITLE_16 = 'How can I get my free credit report?';
const IDS_FAQ_TITLE_17 = 'Why do I get different credit scores from different credit sources?';
const IDS_FAQ_TITLE_18 = 'Someone used my Social Security number, can I get a new one?';
const IDS_FAQ_TITLE_19 = 'I placed a fraud alert, is that enough protection?';
const IDS_FAQ_TITLE_2 = 'What is identity theft?';
const IDS_FAQ_TITLE_3 = 'Are pre-existing issues covered?';
const IDS_FAQ_TITLE_4 = 'What is your cancellation policy?';
const IDS_FAQ_TITLE_5 = 'What is 1 bureau (1B) vs. 3 bureau (3B) monitoring?';
const IDS_FAQ_TITLE_6 = 'How do I know if I’ve been a victim of identity theft?';
const IDS_FAQ_TITLE_7 = 'How does identity theft restoration work?';
const IDS_FAQ_TITLE_8 = 'How does monitoring protect me from identity theft?';
const IDS_FAQ_TITLE_9 = 'Who is covered by the family plan?';

const IDS_FAQ_1 = [
  'There are numerous ways that thieves can obtain personally identifiable information (PII). Examples include:',
  [
    'Stealing physical items such as mail, wallet/purse, smartphone, trash, etc.',
    'Stealing digital data as when a business computer system is breached, your computer is tainted with malware or a skimmer is placed on a payment card reader.',
    'Gathering personal information from social networks and data aggregator websites.',
    'Phishing schemes – tricks to get you to reveal information.',
  ],
];
const IDS_FAQ_2 = `Identity theft is the fraudulent use of personally identifiable information (PII) by a thief to obtain goods, services, and/or employment; commit a crime; gain a benefit; or prevent revealing the thief’s real identity. PII includes but may not be limited to a consumer’s name, Social Security number, date of birth, address, driver’s license number, telephone number, passport information, birth certificate, student transcript, or medical record.`;
const IDS_FAQ_3 = `Most pre-existing identity theft events are not covered under the full restoration benefit. However, our plans can still provide valuable protection and support. Benefits such as unlimited consultation with an identity theft expert to help you deal with pre-existing identity theft, help placing a fraud alert on your credit accounts and ongoing monitoring to track any new issues provide critical support when you need it most. If you’ve already experienced identity theft, join immediately and contact our Licensed Private Investigators to prevent additional damage to your finances and reputation.`;
const IDS_FAQ_4 = `Your membership can be canceled at any time by sending written notice to IDShield. Please see your membership benefits for cancellation instructions specific to your plan, or you can call/email Member Services at (888) 494-8519 or email support@pplsi.com. Important: Your membership and payment remain active until written notice is received.`;
const IDS_FAQ_5 = [
  '“1B” and “3B” monitoring refers to the number of credit bureaus being monitored (the credit bureaus are TransUnion, Equifax, Experian).',

  'Offering 1-bureau monitoring allows us to keep costs of the IDShield product low and still monitor important data. One-bureau monitoring is effective because it is rare that credit-related identity theft is limited to one event and that one event is reported to only one credit bureau. Most of the time all three credit reports will be affected to some extent allowing 1-bureau monitoring to be effective to alert the member to an issue.',

  'With our 3 bureau monitoring plans, IDShield monitors credit reports across all three major credit bureaus: Experian, TransUnion and Equifax.',

  'Lenders, creditors and many businesses check and submit data that ends up on your credit reports. But not all report to the same credit bureau. By watching all three bureaus, IDShield provides comprehensive monitoring. If changes or inquiries are made, participants will receive an instant alert.',

  'It is important to note that when identity theft is confirmed, the restoration process deals with ALL THREE national credit reporting agencies, so that all of the member’s reports are reviewed and any fraudulent information on the reports is disputed.',
];
const IDS_FAQ_6 = `Sometimes a consumer may think identity theft has occurred when it may not actually have. If there is an account on your credit report that you did not authorize, it is possible that an identity thief used your information to create an account or it could simply be the result of a credit reporting error. You can prove you’re a victim of identity theft if you can confirm that your Social Security number or other key piece of your Personally Identifiable Information was used to open a credit card account, obtain a loan, submit health care information or similar unauthorized activity. IDShield members have unlimited consultation with Licensed Private Investigators if they have any questions or concerns regarding the status of their identity.`;
const IDS_FAQ_7 = `IDShield has Licensed Private Investigators to provide the best identity restoration possible and undo the damage done by identity thieves. Our IDShield Licensed Private Investigators will work for as long as it takes to restore a member’s identity to its pre-theft status to ensure that they are not held responsible for the debts created by the identity thief. By performing comprehensive restoration services, consumer reports are returned to their pre-theft status and other records are cleared of the activity created by the identity thief.`;
const IDS_FAQ_8 = `Credit report monitoring in itself does not prevent identity theft. However, it’s a tool that can alert you to activity that may indicate identity theft is being attempted or has taken place. If you learn someone used your data to apply for credit, you’ll take steps to prevent future misuse of your data.`;
const IDS_FAQ_9 = `The participant, their spouse/partner and all dependent children under the age of 18. Note that monitoring services are not available for dependent children ages 18-26 but dependent children of the member or members spouse ages 18-26 are eligible for consultation and restoration services only.`;
const IDS_FAQ_10 = `Identity theft is much more than credit card fraud. It’s the fraudulent use of personally identifiable information (PII) by a thief for the purpose of obtaining goods, services, and/or employment, committing a crime, gaining a benefit or hiding a real identity. It can include a consumer’s name, Social Security number, date of birth, address, driver’s license number, telephone number, passport information, birth certificate information, student transcript data and medical record information.`;

const IDS_FAQ_11 = `Remember, identity theft is not limited to credit fraud. In fact, credit-related identity theft accounts for only a small amount of the identity theft incidents reported to the FTC each year.
Someone can misuse your driver’s license number, make counterfeit checks using your personal information, use your identity to rent an apartment or apply for a payday loan, or give your name and other identifiers to the police if they’re arrested. These activities and others have nothing to do with your credit history.`;
const IDS_FAQ_12 = `Often confused with the “deep web” which is simply those parts of the internet not indexed by any search engine, the “dark web” includes those sites that take measures to hide their IP address to remain anonymous. The most notorious sites on the dark web are those with illegal activities such as the sale of stolen information (think credit/debit card data and personal identifiers that can be used to commit identity theft), drug sales, weapon sales and pornography. If you ever must visit the dark web, you should consider dark web monitoring first.`;
const IDS_FAQ_13 = `Credit and debit card fraud, sometimes referred to as “existing account fraud,” occurs when a thief steals information on an existing account and then uses it to make use of that account, such as for making unauthorized purchases.
In contrast, “new account fraud” happens when the thief uses the victim’s personally identifiable information to establish an entirely new account of which the victim has no knowledge.`;
const IDS_FAQ_14 = `Your credit score is a numerical representation of the history of credit use found in your credit report. Your credit report lists the details of your credit history, which can include creditor names, addresses, loan amounts, credit limits, payments made on time or past due, and amount of monthly payments.`;
const IDS_FAQ_15 = `No. Many reports contain errors. If you find an account on your credit report you don’t recognize, call that creditor and ask them to tell you whether there is an account that was opened with the use of your personal identifying information, particularly, your Social Security number.`;
const IDS_FAQ_16 = `Federal law ensures that all Americans have the right to get a free credit report every 12 months from all three of the consumer reporting agencies (Equifax, Experian and TransUnion). Get yours for free by visiting https://www.AnnualCreditReport.com, calling 1-877-322-8228 or downloading this request form and mailing it to: Annual Credit Report Request Service, P.O. Box 105281, Atlanta, GA 30348-5281.`;

const IDS_FAQ_17 = `Although most credit reports contain the same information, there is a chance that one credit report might have data that a different credit-reporting agency doesn’t have. If you get credit scores that are each based on a different credit report, you could see different credit scores.`;
const IDS_FAQ_18 = `It’s unlikely the Social Security Administration will issue a new number for limited identity theft. In fact, getting a new Social Security number would probably create a new set of problems for you. Remember that your Social Security number is connected to your employment, tax, education and medical records. Seeking a new Social Security number is only considered in extreme situations.
If someone has used your Social Security number, the best first step is to sign up for IDShield and let our systems and licensed investigators protect your identity from further abuse and develop a plan to secure your accounts.`;
const IDS_FAQ_19 = `A fraud alert is a statement on your credit report indicating that you’re vulnerable to becoming a victim or have been a victim of identity theft. It asks a credit reviewer to take reasonable extra steps to verify the identity of the applicant, reducing the chance a thief will succeed in opening new accounts.
While fraud alerts are a great tool, they should not be considered complete protection. There are many other ways thieves can use your personal identification information to steal money and commit fraud beyond credit cards, such as filing false tax returns or initiating fraudulent transfers from your bank accounts, which fraud alerts to do not protect against.`;

const crumbs = [
  { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
  { label: 'Frequently Asked Questions', link: '/faq' },
];

export const FAQ: FC = () => (
  <Layout crumbs={crumbs} subtitle="Frequently Asked Questions" title="FAQ">
    <Card>
      <Card.Content>
        <div className="px-4">
          <TabsRoot defaultValue="tab1" variant="bar" classNames={['pt-6']} stretch={true}>
            <TabsList aria-label="Select a tab" classNames="pb-6">
              <TabsTrigger value="tab1">LegalShield</TabsTrigger>
              <TabsTrigger value="tab2">IDShield</TabsTrigger>
            </TabsList>
            <TabsContent value="tab1">
              <Accordion border={false} id="accordion" title={LS_FAQ_1_TITLE}>
                {LS_FAQ_1}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_2_TITLE}>
                {LS_FAQ_2}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_3_TITLE}>
                {LS_FAQ_3}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_4_TITLE}>
                {LS_FAQ_4.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_5_TITLE}>
                {LS_FAQ_5}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_6_TITLE}>
                {LS_FAQ_6}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_7_TITLE}>
                {LS_FAQ_7}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_8_TITLE}>
                {LS_FAQ_8}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_9_TITLE}>
                {LS_FAQ_9}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_10_TITLE}>
                {LS_FAQ_10}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_11_TITLE}>
                {LS_FAQ_11}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_12_TITLE}>
                {LS_FAQ_12}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_13_TITLE}>
                {LS_FAQ_13.map((paragraph, index) => {
                  if (typeof paragraph === 'string') return <p key={index}>{paragraph}</p>;
                  else if (Array.isArray(paragraph))
                    return (
                      <ul key={index}>{(paragraph as string[])?.map((item, index) => <li key={index}>{item}</li>)}</ul>
                    );
                })}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_14_TITLE}>
                {LS_FAQ_14}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_15_TITLE}>
                {LS_FAQ_15}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_16_TITLE}>
                {LS_FAQ_16.map((item, index) => {
                  if (typeof item === 'string') {
                    return <p key={index}>{item}</p>;
                  } else if (Array.isArray(item)) {
                    return (
                      <ul key={index}>
                        {(item as string[]).map((paragraph, index) => (
                          <li key={index}>{paragraph}</li>
                        ))}
                      </ul>
                    );
                  }
                })}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_17_TITLE}>
                {LS_FAQ_17}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_18_TITLE}>
                {LS_FAQ_18}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_19_TITLE}>
                {LS_FAQ_19}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_20_TITLE}>
                {LS_FAQ_20}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_21_TITLE}>
                {LS_FAQ_21}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_22_TITLE}>
                {LS_FAQ_22}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_23_TITLE}>
                {LS_FAQ_23}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_24_TITLE}>
                {LS_FAQ_24}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_25_TITLE}>
                {LS_FAQ_25.map((paragraph, index) => {
                  if (typeof paragraph === 'string') return <p key={index}>{paragraph}</p>;
                  else if (Array.isArray(paragraph))
                    return (
                      <ul key={index}>
                        {(paragraph as string[]).map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    );
                })}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_26_TITLE}>
                {LS_FAQ_26.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_27_TITLE}>
                {LS_FAQ_27}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_28_TITLE}>
                {LS_FAQ_28.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_29_TITLE}>
                {LS_FAQ_29}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_30_TITLE}>
                {LS_FAQ_30}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_31_TITLE}>
                {LS_FAQ_31}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_32_TITLE}>
                {LS_FAQ_32}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_33_TITLE}>
                {LS_FAQ_33}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_34_TITLE}>
                {LS_FAQ_34}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_35_TITLE}>
                {LS_FAQ_35.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_36_TITLE}>
                {LS_FAQ_36}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_37_TITLE}>
                {LS_FAQ_37}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_38_TITLE}>
                {LS_FAQ_38}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_39_TITLE}>
                {LS_FAQ_39}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_40_TITLE}>
                {LS_FAQ_40}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_41_TITLE}>
                {LS_FAQ_41}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_42_TITLE}>
                {LS_FAQ_42}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_43_TITLE}>
                {LS_FAQ_43}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_44_TITLE}>
                {LS_FAQ_44}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_45_TITLE}>
                {LS_FAQ_45.map((paragraph, index) => {
                  if (typeof paragraph === 'string') return <p key={index}>{paragraph}</p>;
                  else if (Array.isArray(paragraph))
                    return (
                      <ul key={index}>
                        {(paragraph as string[]).map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    );
                })}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_46_TITLE}>
                {LS_FAQ_46}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_47_TITLE}>
                {LS_FAQ_47}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_48_TITLE}>
                {LS_FAQ_48}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_49_TITLE}>
                {LS_FAQ_49}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_50_TITLE}>
                {LS_FAQ_50}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_51_TITLE}>
                {LS_FAQ_51}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_52_TITLE}>
                {LS_FAQ_52}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_53_TITLE}>
                {LS_FAQ_53}
              </Accordion>
              <Accordion id="accordion" title={LS_FAQ_54_TITLE}>
                {LS_FAQ_54}
              </Accordion>
            </TabsContent>
            <TabsContent value="tab2">
              <Accordion border={false} id="accordion" title={IDS_FAQ_TITLE_1}>
                {IDS_FAQ_1.map((paragraph, index) => {
                  if (typeof paragraph === 'string') return <p key={index}>{paragraph}</p>;
                  else if (Array.isArray(paragraph))
                    return (
                      <ul key={index}>
                        {(paragraph as string[]).map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    );
                })}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_2}>
                {IDS_FAQ_2}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_3}>
                {IDS_FAQ_3}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_4}>
                {IDS_FAQ_4}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_5}>
                {IDS_FAQ_5.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_6}>
                {IDS_FAQ_6}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_7}>
                {IDS_FAQ_7}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_8}>
                {IDS_FAQ_8}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_9}>
                {IDS_FAQ_9}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_10}>
                {IDS_FAQ_10}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_11}>
                {IDS_FAQ_11}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_12}>
                {IDS_FAQ_12}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_13}>
                {IDS_FAQ_13}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_14}>
                {IDS_FAQ_14}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_15}>
                {IDS_FAQ_15}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_16}>
                {IDS_FAQ_16}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_17}>
                {IDS_FAQ_17}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_18}>
                {IDS_FAQ_18}
              </Accordion>
              <Accordion id="accordion" title={IDS_FAQ_TITLE_19}>
                {IDS_FAQ_19}
              </Accordion>
            </TabsContent>
          </TabsRoot>
        </div>
      </Card.Content>
    </Card>
  </Layout>
);

export default FAQ;
